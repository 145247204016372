.maplibre-ctrl-labels {
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: auto;
}

.maplibre-ctrl-labels-off {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' version='1.1' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='grey' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M7 6.2V5h12v1.2M7 19h6m.2-14-1.7 6.5M9.6 19l1-4M5 5l6.5 6.5M19 19l-7.5-7.5'/%3E%3C/svg%3E");
}

.maplibre-ctrl-labels-on {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' version='1.1' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='orange' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M3 6.2V5h11v1.2M8 5v14m-3 0h6m2-6.8V11h8v1.2M17 11v8m-1.5 0h3'/%3E%3C/svg%3E");
}
